<template>
    <div class="forxSignals-page">
        <section class="blockElement bg-white space">
            <div class="container">
                <div class="row align-items-center padDirection">
                    <div class="col-12 col-xl-5 line-60 mob-center">
                        <h1 class="extrabold mb-md-3 titleArrow">{{$t('forexTrading.forexText1')}} <span class="secondary">{{$t('forexTrading.forexText1a')}}</span> {{$t('forexTrading.forexText1b')}}</h1>
                        <h5 class="mb-3 mb-md-4">{{$t('forexTrading.forexText2')}}</h5>
                        <div class="singleButton">
                            <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn px-md-5 shadow">{{$t('forexTrading.forexText3')}}</router-link>
                            <a v-else :href="'/register/'" class="button fillBtn zulu_btn px-md-5 shadow">{{$t('forexTrading.forexText3')}}</a>
                        </div>
                    </div>
                    <div class="col-12 col-xl-7">
                        <div class="meetImg">
                            <v-lazy-image width="650" height="400" class="br-12 d-block ms-auto" src="/assets/images/funded/planning-future.jpg" :alt="$t('forexTrading.forexText4')" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space">
            <div class="container p-0 whoWeAre">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-9 text-center">
                        <div class="boxed bg-white forexTrading">
                            <h2 class="semibold mb-md-4">{{$t('forexTrading.forexText5')}}</h2>
                            <p>{{$t('forexTrading.forexText6')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement bg-white space">
            <div class="container">
                <div class="row">
                    <div class="col-12 mb-4 mb-md-5 text-center semibold f-40">
                        <h2>{{$t('forexTrading.forexText7')}} <span class="secondary">{{$t('forexTrading.forexText7a')}}</span></h2>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-4">
                        <div class="card-body h-100 cardBody p-3 mobCenter">
                            <span class="d-block mb-4"><img src="/assets/images/create-account.svg" class="d-block h-70 v-lazy-image v-lazy-image-loaded" :alt="$t('forexTrading.forexText8')" :title="$t('forexTrading.forexText9')" /></span>
                            <h4 class="bold">{{$t('forexTrading.forexText9')}}</h4>
                            <p>{{$t('forexTrading.forexText10')}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-4">
                        <div class="card-body h-100 cardBody p-3 mobCenter">
                            <span class="d-block mb-4"><img src="/assets/images/promote.svg" class="d-block h-70 v-lazy-image v-lazy-image-loaded" :alt="$t('forexTrading.forexText12')" :title="$t('forexTrading.forexText11')" /></span>
                            <h4 class="bold">{{$t('forexTrading.forexText13')}}</h4>
                            <p>{{$t('forexTrading.forexText14')}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-4">
                        <div class="card-body h-100 cardBody p-3 mobCenter">
                            <span class="d-block mb-4"><img src="/assets/images/layer_1.svg" class="d-block h-70 v-lazy-image v-lazy-image-loaded" :alt="$t('forexTrading.forexText15')" :title="$t('forexTrading.forexText16')" /></span>
                            <h4 class="bold">{{$t('forexTrading.forexText17')}}</h4>
                            <p>{{$t('forexTrading.forexText18')}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-4">
                        <div class="card-body h-100 cardBody p-3 mobCenter">
                            <span class="d-block mb-4"><img src="/assets/images/layer_3.svg" class="d-block h-70 v-lazy-image v-lazy-image-loaded" :alt="$t('forexTrading.forexText19')" :title="$t('forexTrading.forexText19')" /></span>
                            <h4 class="bold">{{$t('forexTrading.forexText19')}}</h4>
                            <p>{{$t('forexTrading.forexText20')}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-4">
                        <div class="card-body h-100 cardBody p-3 mobCenter">
                            <span class="d-block mb-4"><img src="/assets/images/layer_4.svg" class="d-block h-70 v-lazy-image v-lazy-image-loaded" :alt="$t('forexTrading.forexText21')" :title="$t('forexTrading.forexText21')" /></span>
                            <h4 class="bold">{{$t('forexTrading.forexText21')}}</h4>
                            <p>{{$t('forexTrading.forexText22')}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-4">
                        <div class="card-body h-100 cardBody p-3 mobCenter">
                            <span class="d-block mb-4"><img src="/assets/images/layer_2.svg" class="d-block h-70 v-lazy-image v-lazy-image-loaded" :alt="$t('forexTrading.forexText23')" :title="$t('forexTrading.forexText23')" /></span>
                            <h4 class="bold">{{$t('forexTrading.forexText23')}}</h4>
                            <p>{{$t('forexTrading.forexText24')}}</p>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="text-center mt-4">
                            <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('forexTrading.forexText25')}}</router-link>
                            <a v-else :href="'/register/'" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('forexTrading.forexText25')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="blockElement space">
            <div class="container">
                <div class="row align-items-center padDirection">
                    <div class="col-12 col-lg-5">
                        <h2 class="mb-3 semibold f-40">{{$t('forexTrading.forexText26')}}</h2>
                        <p>{{$t('forexTrading.forexText27')}}</p>
                        <div class="mt-md-5 mt-3">
                            <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn me-md-4 px-md-5 shadow">{{$t('forexTrading.forexText25')}}</router-link>
                            <a v-else :href="'/register/'" class="button fillBtn zulu_btn me-md-4 px-md-5 shadow">{{$t('forexTrading.forexText25')}}</a>
                        </div>
                    </div>
                    <div class="col-12 col-lg-7">
                        <div class="meetImg">
                            <v-lazy-image width="600" height="400" class="br-12 d-block mx-auto" src="/assets/images/funded/smiley-people.jpg" :alt="$t('forexTrading.forexText28')" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-white">
            <div class="container">
                <div class="row justify-content-center">
                    <!-- <div class="col-12 col-lg-7">
                        <div class="meetImg">
                            <v-lazy-image width="557" height="400" class="br-12 d-block mx-auto" src="/assets/images/funded/planning-future.jpg" :alt="$t('forexTrading.forexText29')" />
                        </div>
                    </div> -->
                    <div class="col-12 col-lg-10 text-center">
                        <h2 class="mb-3 semibold f-40">{{$t('forexTrading.forexText29')}}</h2>
                        <p>{{$t('forexTrading.forexText30')}}</p>
                        <div class="mt-md-5 mt-3">
                            <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn me-md-4 px-md-5 shadow">{{$t('forexTrading.forexText25')}}</router-link>
                            <a v-else :href="'/register/'" class="button fillBtn zulu_btn me-md-4 px-md-5 shadow">{{$t('forexTrading.forexText25')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space transparentForex">
            <div class="container">
                <div class="row ">
                    <!-- <div class="col-12 mb-4 mb-md-5 text-center"><h2 class="semibold f-40">Forex Trading Strategy</h2></div> -->
                    <div class="col-12 col-lg-4 mb-4">
                        <v-lazy-image width="719"  src="/assets/images/funded/followingValue.png" alt="Subscription Plan" />
                    </div>
                    <div class="col-12 col-lg-8 mb-lg-4">
                        <!-- <div class="card-body mb-3 bg-white p-md-4 p-3">
                            <h4 class="bold">{{$t('forexTrading.forexText31')}} <span class="secondary">{{$t('forexTrading.forexText31a')}}</span> {{$t('forexTrading.forexText31b')}}</h4>
                            <p class="mb-0">{{$t('forexTrading.forexText32')}} <span class="secondary">{{$t('forexTrading.forexText32a')}}</span> {{$t('forexTrading.forexText32b')}}</p>
                        </div> -->
                        <div class="card-body mb-3 bg-white p-md-4 p-3">
                            <h4 class="bold">{{$t('forexTrading.forexText33')}} <span class="secondary">{{$t('forexTrading.forexText33a')}}</span></h4>
                            <p class="mb-0">{{$t('forexTrading.forexText34')}} <span class="secondary">{{$t('forexTrading.forexText34a')}}</span> {{$t('forexTrading.forexText34b')}}</p>
                        </div>
                        <div class="card-body mb-3 bg-white p-md-4 p-3">
                            <h4 class="bold">{{$t('forexTrading.forexText35')}}</h4>
                            <p class="mb-0">{{$t('forexTrading.forexText36')}} <span class="secondary">{{$t('forexTrading.forexText36a')}}</span> {{$t('forexTrading.forexText36b')}}</p>
                        </div>
                        <div class="card-body mb-3 bg-white p-md-4 p-3">
                            <h4 class="bold">{{$t('forexTrading.forexText37')}} <span class="secondary">{{$t('forexTrading.forexText37a')}}</span> {{$t('forexTrading.forexText37b')}}</h4>
                            <p class="mb-0">{{$t('forexTrading.forexText38')}} <span class="secondary">{{$t('forexTrading.forexText38a')}}</span> {{$t('forexTrading.forexText38b')}}</p>
                        </div>
                        <div class="card-body mb-3 bg-white p-md-4 p-3">
                            <h4 class="bold">{{$t('forexTrading.forexText39')}} <span class="secondary">{{$t('forexTrading.forexText39a')}}</span></h4>
                            <p class="mb-0">{{$t('forexTrading.forexText40')}} <span class="secondary">{{$t('forexTrading.forexText40a')}}</span> {{$t('forexTrading.forexText40b')}}</p>
                        </div>
                        <div class="openAccount pt-4">
                            <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('forexTrading.forexText25')}}</router-link>
                            <a v-else :href="'/register/'" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('forexTrading.forexText25')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-white howWork">
            <div class="container">
                <div class="row justify-content-center padDirection">
                    <div class="col-12 col-lg-6 mb-0">
                        <h2 class="mb-3 semibold f-40">{{$t('forexTrading.forexText41')}} <span class="secondary">{{$t('forexTrading.forexText41a')}}</span> {{$t('forexTrading.forexText41b')}}</h2>
                        <p>{{$t('forexTrading.forexText42')}}</p>
                        <ul class="checkList mt-4">
                            <li>{{$t('forexTrading.forexText43')}}</li>
                            <li>{{$t('forexTrading.forexText44')}}</li>
                            <!-- <li>{{$t('forexTrading.forexText45')}}</li> -->
                            <li>{{$t('forexTrading.forexText46')}}</li>
                            <li>{{$t('forexTrading.forexText47')}}</li>
                        </ul>
                        <p class="mb-1"><strong>{{$t('forexTrading.forexText48')}}</strong> {{$t('forexTrading.forexText48a')}}</p>
                        <p class="mb-1">{{$t('forexTrading.forexText49')}} <strong>{{$t('forexTrading.forexText49a')}}</strong> {{$t('forexTrading.forexText49b')}}</p>
                        <!-- <p class="mb-1">{{$t('forexTrading.forexText50')}} <strong>{{$t('forexTrading.forexText50a')}}</strong> {{$t('forexTrading.forexText50b')}} <strong>{{$t('forexTrading.forexText50c')}}</strong> {{$t('forexTrading.forexText50d')}}</p> -->
                        <p class="mb-1"><strong>{{$t('forexTrading.forexText51')}}</strong> {{$t('forexTrading.forexText51a')}}</p>
                        <p class="mb-1"><strong>{{$t('forexTrading.forexText52')}}</strong></p>
                        <div class="mt-4">
                            <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn me-md-4 px-md-5 shadow">{{$t('forexTrading.forexText25')}}</router-link>
                                <a v-else :href="'/register/'" class="button fillBtn zulu_btn me-md-4 px-md-5 shadow">{{$t('forexTrading.forexText25')}}</a>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <v-lazy-image width="557" height="400" class="br-12 d-block mx-auto" src="/assets/images/funded/man-using.jpg" :alt="$t('forexTrading.forexText53')" />
                    </div>

                </div>
            </div>
        </section>
        <section class="blockElement space faqs automated6">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                         <h2 class="mb-md-4 pb-4 text-center">{{$t('about.text253')}}</h2>
                    </div>
                    <div class="col-12">
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                            <!-- <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingOne">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    {{$t('forexTrading.forexText54')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">{{$t('forexTrading.forexText55')}}</div>
                                 </div>
                            </div> -->
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingFour">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    {{$t('forexTrading.forexText56')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('forexTrading.forexText57')}}</div>
                                 </div>
                            </div>
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingfive">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                                    {{$t('forexTrading.forexText58')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('forexTrading.forexText59')}}</div>
                                 </div>
                            </div>
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingsix">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                                    {{$t('forexTrading.forexText60')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapsesix" class="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">
                                    <span class="d-block mb-3">{{$t('forexTrading.forexText61')}}</span>
                                    <span class="d-block mb-3">{{$t('forexTrading.forexText62')}}.</span>
                                    <span class="d-block">{{$t('forexTrading.forexText63')}} <a class="secondary" href="/terms-of-service" target="_blank"> {{$t('forexTrading.forexText64')}}</a></span>
                                   </div>
                                 </div>
                            </div>
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingSeven">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                    {{$t('forexTrading.forexText65')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('forexTrading.forexText66')}}</div>
                                 </div>
                            </div>
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingEight">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                    {{$t('forexTrading.forexText67')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('forexTrading.forexText68')}}</div>
                                 </div>
                            </div>
                            <!-- <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingNine">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                    {{$t('forexTrading.forexText69')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('forexTrading.forexText70')}}</div>
                                 </div>
                            </div> -->
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingTen">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                    {{$t('forexTrading.forexText71')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('forexTrading.forexText72')}}</div>
                                 </div>
                            </div>
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingEleven">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                    {{$t('forexTrading.forexText73')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('forexTrading.forexText74')}}</div>
                                 </div>
                            </div>
                            <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingTwelve">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                    {{$t('forexTrading.forexText75')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseTwelve" class="accordion-collapse collapse" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('forexTrading.forexText76')}}</div>
                                 </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingThirteen">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                                    {{$t('forexTrading.forexText77')}}
                                  </button>
                                </h2>
                                <div id="flush-collapseThirteen" class="accordion-collapse collapse" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">{{$t('forexTrading.forexText78')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingfourteen">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefourteen" aria-expanded="false" aria-controls="flush-collapsefourteen">
                                    {{$t('forexTrading.forexText79')}}
                                </button>
                                </h2>
                                <div id="flush-collapsefourteen" class="accordion-collapse collapse" aria-labelledby="flush-headingfourteen" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">{{$t('forexTrading.forexText80')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingfifteen">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefifteen" aria-expanded="false" aria-controls="flush-collapsefifteen">
                                    {{$t('forexTrading.forexText81')}}
                                </button>
                                </h2>
                                <div id="flush-collapsefifteen" class="accordion-collapse collapse" aria-labelledby="flush-headingfifteen" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <span class="d-block mb-3">{{$t('forexTrading.forexText82')}}</span>
                                        <span class="d-block mb-3">{{$t('forexTrading.forexText83')}}.</span>
                                        <div class="d-block mb-2">
                                            <a class="secondary f-14" href="/risk-disclaimer" target="_blank">{{$t('forexTrading.forexText84')}}</a>
                                        </div>
                                        <div class="d-block">
                                            <a class="secondary f-14" href="/terms-of-service" target="_blank">{{$t('forexTrading.forexText85')}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingsixteen">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesixteen" aria-expanded="false" aria-controls="flush-collapsesixteen">
                                    {{$t('forexTrading.forexText86')}}
                                </button>
                                </h2>
                                <div id="flush-collapsesixteen" class="accordion-collapse collapse" aria-labelledby="flush-headingsixteen" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">{{$t('forexTrading.forexText87')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingseventeen">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseventeen" aria-expanded="false" aria-controls="flush-collapseseventeen">
                                    {{$t('forexTrading.forexText88')}}
                                </button>
                                </h2>
                                <div id="flush-collapseseventeen" class="accordion-collapse collapse" aria-labelledby="flush-headingseventeen" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">{{$t('forexTrading.forexText89')}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return {

        };
    },
}
</script>